import { Base } from '@wilson/base';
import { WithTranslations } from '../translations/translations';
import { FormControl } from '@angular/forms';

export interface PayrollCategory extends Partial<WithTranslations>, Base {
  name: string;
  translatedName: string;
}

export enum PayrollCategoryDrawerFormFieldEnum {
  PayrollCategory = 'payrollCategory',
  Name = 'name',
  ProviderEditingKey = 'providerEditingKey',
  ExternalCategoryId = 'externalCategoryId',
  Unit = 'unit',
  DefaultCostCenter = 'defaultCostCenter',
}

export enum ExportParameterUnitName {
  Eur = 'eur',
  Hour = 'hour',
  Minute = 'minute',
  Kilometer = 'kilometer',
  Week = 'week',
  Month = 'month',
  Day = 'day',
  Year = 'year',
}

export interface PayrollCategoryDrawerFormValue {
  [PayrollCategoryDrawerFormFieldEnum.PayrollCategory]: string | null;
  [PayrollCategoryDrawerFormFieldEnum.Name]: string | null;
  [PayrollCategoryDrawerFormFieldEnum.ProviderEditingKey]: number | null;
  [PayrollCategoryDrawerFormFieldEnum.ExternalCategoryId]: number[] | null;
  [PayrollCategoryDrawerFormFieldEnum.Unit]: ExportParameterUnitName | null;
  [PayrollCategoryDrawerFormFieldEnum.DefaultCostCenter]: number | null;
}

export type PayrollCategoryFormControls = {
  [Property in keyof PayrollCategoryDrawerFormValue]: FormControl<
    PayrollCategoryDrawerFormValue[Property]
  >;
};

export enum PayrollCategoryType {
  AddNewCategory = 'addNewCategory',
}
