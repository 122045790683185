import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BackendService, TypeOrmFindManyOptions } from '@wilson/base';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { TransformMasterDataTranslationService } from '@wilson/non-domain-specific/master-data-translate';
import {
  CreatePayrollSetting,
  CreatePayrollSettingCategory,
  PayrollSetting,
  PayrollSettingCategory,
  UserPayrollSetting,
} from '@wilson/payroll/interfaces';
import { stringify } from 'qs';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PayrollSettingsService extends BackendService<PayrollSetting> {
  protected readonly path = 'payroll-settings';

  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
    protected readonly transformMasterDataTranslationService: TransformMasterDataTranslationService,
  ) {
    super();
  }

  public getPayrollSettingAssignedUsers(
    id: string,
  ): Observable<UserPayrollSetting[]> {
    return this.http.get<UserPayrollSetting[]>(
      `${this.config.host}/${this.path}/${id}/active-users`,
    );
  }

  public getActiveUserPayrollSetting(
    userId: string,
    date: string,
  ): Observable<UserPayrollSetting> {
    return this.http.get<UserPayrollSetting>(
      `${this.config.host}/user-payroll-settings/${userId}/active/${date}`,
    );
  }
  public bulkDeleteUsersAssignedPayrollSetting(userIds: string[]) {
    return this.http.patch(`${this.config.host}/user-payroll-settings/delete`, {
      ids: userIds,
    });
  }

  public createUserPayrollSetting(
    payload: CreatePayrollSetting[],
  ): Observable<PayrollSetting> {
    return this.http.post<PayrollSetting>(
      `${this.config.host}/user-payroll-settings`,
      {
        items: payload,
      },
    );
  }

  public getPayrollSetting(
    payrollSettingId: string,
    options?: TypeOrmFindManyOptions,
  ): Observable<PayrollSetting> {
    const params = stringify(options);
    return this.http
      .get<PayrollSetting>(
        `${this.config.host}/${this.path}/${payrollSettingId}?${params}`,
      )
      .pipe(
        map((payrollSetting) => {
          payrollSetting.payrollSettingCategories.map(
            (payrollSettingCategory) => {
              if (payrollSettingCategory.payrollCategory) {
                payrollSettingCategory.payrollCategory =
                  this.transformMasterDataTranslationService.transform([
                    payrollSettingCategory.payrollCategory,
                  ])[0];
              }
              return payrollSettingCategory;
            },
          );
          return payrollSetting;
        }),
      );
  }

  public createPayrollSettingCategory(
    payload: CreatePayrollSettingCategory,
  ): Observable<PayrollSettingCategory> {
    return this.http.post<PayrollSettingCategory>(
      `${this.config.host}/payroll-setting-categories`,
      payload,
    );
  }
}
