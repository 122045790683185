import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import {
  ActivitiesV2Gateway,
  PartialActivityWhereInput,
  RequestForProposalGateway,
} from '@wilson/api/gateway';
import {
  ActivitiesJob,
  Activity,
  ActivityCategory,
  ActivitySelectionListItem,
  RequestForProposal,
  Shift,
  User,
} from '@wilson/interfaces';
import {
  endOfDay,
  endOfMonth,
  startOfDay,
  startOfMonth,
  subMonths,
} from 'date-fns';
import { flatten } from 'lodash';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable, catchError, combineLatest, map, of, tap } from 'rxjs';
import {
  ActivityInvoiceModel,
  ActivityViewModel,
  InvoiceDateTypeFilter,
  InvoicingDataStateModel,
} from '../interfaces/invoicing-custom-types';
import {
  InvoiceDataHelperService,
  Invoiced,
  ShiftLabels,
  ShiftProjects,
  ShiftUserLabels,
  ShiftUserProfessions,
} from './invoicing-data-helper.service';
import {
  ChangePagination,
  InvoicingFetchShifts,
  InvoicingFilterOnlyServiceActivities,
  InvoicingSetActivityCategories,
  InvoicingSetActivityStatus,
  InvoicingSetAgreements,
  InvoicingSetClient,
  InvoicingSetDateTypeFilterIndex,
  InvoicingSetEmployeeOrgUnits,
  InvoicingSetEmployees,
  InvoicingSetJobs,
  InvoicingSetPartners,
  InvoicingSetSearchString,
  InvoicingSetSelectedActivity,
  InvoicingSetShiftCategories,
  InvoicingSetShiftLabels,
  InvoicingSetShiftProfessions,
  InvoicingSetShiftProjects,
  InvoicingSetTimeRange,
  InvoicingSetUserLabels,
  SetSelectedActivities,
} from './invoicing-data.actions';

interface MappedActivitySelectionItems {
  data: ActivitySelectionListItem[];
}

const defaults: InvoicingDataStateModel = {
  activities: null,
  selectedActivities: [],
  selectedClientId: null,
  selectedClientName: null,
  selectedActivityStates: {
    isSameClient: false,
    doActivitiesHaveAgreements: false,
  },
  filters: {
    agreements: [],
    partners: [],
    jobId: [],
    shiftUserLabelIds: [],
    shiftLabelsIds: [],
    shiftProjectIds: [],
    profession: [],
    orgUnit: [],
    searchString: null,
    dateTypeFilterIndex: InvoiceDateTypeFilter.ByService,
    dateRange: {
      start: startOfMonth(subMonths(new Date(), 1)),
      end: endOfMonth(subMonths(new Date(), 1)),
    },
    employees: [],
    shiftCategories: [],
    activityCategories: [],
    showServiceActivities: false,
    invoiced: null,
  },
  pageNumber: 1,
  pageSize: 50,
  pagination: {
    currentPage: 1,
    numberOfPages: 0,
    pageSize: 0,
    totalRecords: 0,
  },
  pageSizes: [20, 50, 100],
};

@State<InvoicingDataStateModel>({
  name: 'invoicingData',
  defaults,
})
@Injectable()
export class InvoicingDataState {
  constructor(
    private readonly activitiesGateway: ActivitiesV2Gateway,
    private readonly translate: TranslateService,
    private readonly datePipe: DatePipe,
    private readonly message: NzMessageService,
    private readonly store: Store,
    private readonly requestForProposalGatewayService: RequestForProposalGateway,
    private readonly invoiceDataHelperService: InvoiceDataHelperService,
  ) {}

  @Selector()
  static activities(state: InvoicingDataStateModel) {
    return state.activities;
  }

  @Selector()
  static getDateTypeFilterIndex(state: InvoicingDataStateModel) {
    return state.filters.dateTypeFilterIndex;
  }

  @Selector()
  static getDateRange(state: InvoicingDataStateModel) {
    return state.filters.dateRange;
  }

  @Selector()
  static selectedActivitiesCount(state: InvoicingDataStateModel) {
    return state.selectedActivities.length;
  }

  @Selector()
  static selectedActivityIds(state: InvoicingDataStateModel) {
    return state.selectedActivities
      .map((activity) => activity.id)
      .filter((id): id is string => id !== undefined);
  }

  @Selector()
  static selectedActivities(state: InvoicingDataStateModel) {
    return state.selectedActivities;
  }

  @Selector()
  static selectedClientId(state: InvoicingDataStateModel) {
    return state.selectedClientId;
  }

  @Selector()
  static selectedClientName(state: InvoicingDataStateModel) {
    return state.selectedClientName;
  }

  @Selector()
  static allSelectedActivitiesHaveSameAgreement(
    state: InvoicingDataStateModel,
  ) {
    const selectedActivities = state.selectedActivities;
    return selectedActivities.every(
      (activity) => activity.agreementId === selectedActivities[0].agreementId,
    );
  }

  @Selector()
  static stateOfSelectedActivities(state: InvoicingDataStateModel) {
    return state.selectedActivityStates;
  }

  @Selector()
  static getFilters(state: InvoicingDataStateModel) {
    return state.filters;
  }

  @Selector()
  static getPagination(state: InvoicingDataStateModel) {
    return state.pagination;
  }

  @Selector()
  static getPageSize(state: InvoicingDataStateModel) {
    return state.pageSize;
  }

  @Selector()
  static pageSizes(state: InvoicingDataStateModel) {
    return state.pageSizes;
  }

  @Action(InvoicingSetDateTypeFilterIndex)
  invoicingSetDateTypeFilterIndex(
    ctx: StateContext<InvoicingDataStateModel>,
    action: InvoicingSetDateTypeFilterIndex,
  ) {
    ctx.setState(
      patch({
        filters: patch({
          dateTypeFilterIndex: action.index,
        }),
      }),
    );
  }

  @Action(InvoicingSetTimeRange)
  timelineSetDate(
    ctx: StateContext<InvoicingDataStateModel>,
    action: InvoicingSetTimeRange,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      filters: {
        ...state.filters,
        dateRange: {
          start: startOfDay(action.date.start),
          end: endOfDay(action.date.end),
        },
      },
    });
  }

  @Action(InvoicingSetAgreements)
  invoicingSetAgreements(
    ctx: StateContext<InvoicingDataStateModel>,
    action: InvoicingSetAgreements,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      filters: {
        ...state.filters,
        agreements: action.agreements,
      },
    });
  }

  @Action(InvoicingSetEmployees)
  invoicingSetEmployees(
    ctx: StateContext<InvoicingDataStateModel>,
    action: InvoicingSetEmployees,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      filters: {
        ...state.filters,
        employees: action.employees,
      },
    });
  }

  @Action(InvoicingSetShiftCategories)
  invoicingSetShiftCategories(
    ctx: StateContext<InvoicingDataStateModel>,
    action: InvoicingSetShiftCategories,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      filters: {
        ...state.filters,
        shiftCategories: action.shiftCategories,
      },
    });
  }

  @Action(InvoicingSetActivityCategories)
  invoicingSetActivityCategories(
    ctx: StateContext<InvoicingDataStateModel>,
    action: InvoicingSetActivityCategories,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      filters: {
        ...state.filters,
        activityCategories: action.activityCategories,
      },
    });
  }

  @Action(InvoicingSetPartners)
  InvoicingSetPartners(
    ctx: StateContext<InvoicingDataStateModel>,
    action: InvoicingSetPartners,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      filters: {
        ...state.filters,
        partners: action.partners,
      },
    });
  }

  @Action(InvoicingSetJobs)
  InvoicingSetJobs(
    ctx: StateContext<InvoicingDataStateModel>,
    action: InvoicingSetJobs,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      filters: {
        ...state.filters,
        jobId: action.jobIds,
      },
    });
  }

  @Action(InvoicingSetUserLabels)
  InvoicingSetUserLabels(
    ctx: StateContext<InvoicingDataStateModel>,
    action: InvoicingSetUserLabels,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      filters: {
        ...state.filters,
        shiftUserLabelIds: action.shiftUserLabelIds,
      },
    });
  }

  @Action(InvoicingSetShiftLabels)
  InvoicingSetShiftLabels(
    ctx: StateContext<InvoicingDataStateModel>,
    action: InvoicingSetShiftLabels,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      filters: {
        ...state.filters,
        shiftLabelsIds: action.shiftLabelIds,
      },
    });
  }

  @Action(InvoicingSetShiftProjects)
  InvoicingSetShiftProjects(
    ctx: StateContext<InvoicingDataStateModel>,
    action: InvoicingSetShiftProjects,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      filters: {
        ...state.filters,
        shiftProjectIds: action.shiftProjectIds,
      },
    });
  }

  @Action(InvoicingSetShiftProfessions)
  InvoicingSetShiftProfessions(
    ctx: StateContext<InvoicingDataStateModel>,
    action: InvoicingSetShiftProfessions,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      filters: {
        ...state.filters,
        profession: action.professions,
      },
    });
  }

  @Action(InvoicingSetEmployeeOrgUnits)
  InvoicingSetEmployeeOrgUnits(
    ctx: StateContext<InvoicingDataStateModel>,
    action: InvoicingSetEmployeeOrgUnits,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      filters: {
        ...state.filters,
        orgUnit: action.orgUnit,
      },
    });
  }

  @Action(InvoicingSetActivityStatus)
  invoicingSetActivityStatus(
    ctx: StateContext<InvoicingDataStateModel>,
    action: InvoicingSetActivityStatus,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      filters: {
        ...state.filters,
        invoiced: action.status,
      },
    });
  }

  @Action(InvoicingSetSearchString)
  invoicingSetSearchString(
    ctx: StateContext<InvoicingDataStateModel>,
    action: InvoicingSetSearchString,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      filters: {
        ...state.filters,
        searchString: action.searchString,
      },
    });
  }

  @Action(InvoicingFilterOnlyServiceActivities)
  InvoicingFilterOnlyServiceActivities(
    ctx: StateContext<InvoicingDataStateModel>,
    action: InvoicingFilterOnlyServiceActivities,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      filters: {
        ...state.filters,
        showServiceActivities: action.showServiceActivities,
      },
    });
  }

  @Action(ChangePagination)
  changePagination(
    ctx: StateContext<InvoicingDataStateModel>,
    action: ChangePagination,
  ) {
    ctx.patchState({
      pageNumber: action.pageNumber,
    });
  }

  @Action(InvoicingSetSelectedActivity)
  setSelectedActivityIds(
    ctx: StateContext<InvoicingDataStateModel>,
    action: InvoicingSetSelectedActivity,
  ) {
    ctx.patchState({
      selectedActivities: action.activities,
    });
  }

  @Action(InvoicingSetClient)
  invoicingSetClient(
    ctx: StateContext<InvoicingDataStateModel>,
    action: InvoicingSetClient,
  ) {
    ctx.patchState({
      selectedClientId: action.client.id,
      selectedClientName: action.client.name,
    });
  }

  @Action(SetSelectedActivities)
  addStateOfSelectedActivities(
    ctx: StateContext<InvoicingDataStateModel>,
    action: SetSelectedActivities,
  ) {
    ctx.patchState({
      selectedActivityStates: action.activitiesStates,
    });
  }

  @Action(InvoicingFetchShifts)
  fetchUsers(
    ctx: StateContext<InvoicingDataStateModel>,
    action: InvoicingFetchShifts,
  ) {
    const state = ctx.getState();
    const where: PartialActivityWhereInput =
      this.invoiceDataHelperService.getWhere(state);
    const invoicing = this.invoiceDataHelperService.getInvoicing(state);
    const shiftLabelIds = this.invoiceDataHelperService.getShiftLabelIds(state);
    const shiftProjectIds =
      this.invoiceDataHelperService.getShiftProjectIds(state);
    const shiftUserLabelIds =
      this.invoiceDataHelperService.getShiftUserLabelIds(state);
    const shiftUserProfessionIds =
      this.invoiceDataHelperService.getShiftUserProfessionIds(state);

    if (action.hasWilsonShareAccess) {
      return this.fetchShiftsWithSharedActivities(
        where,
        invoicing,
        shiftLabelIds,
        shiftProjectIds,
        shiftUserLabelIds,
        shiftUserProfessionIds,
        state,
        ctx,
      );
    } else {
      return this.fetchShiftsActivities(
        where,
        invoicing,
        shiftLabelIds,
        shiftProjectIds,
        shiftUserLabelIds,
        shiftUserProfessionIds,
        state,
        ctx,
      );
    }
  }

  private fetchAndTransformJobActivities(
    startDate: number | Date,
    endDate: number | Date,
    relations: string[],
  ): Observable<MappedActivitySelectionItems> {
    return this.requestForProposalGatewayService
      .getRequestsForProposalsInRange({
        relations,
        where: {
          startDate: new Date(startDate).toISOString(),
          endDate: new Date(endDate).toISOString(),
        },
      })
      .pipe(
        map(
          (
            requestForProposals: RequestForProposal[],
          ): MappedActivitySelectionItems => {
            return {
              data: flatten(
                requestForProposals.map(
                  (request): ActivitySelectionListItem[] => {
                    const job = request.job as ActivitiesJob;
                    return job.activities.map(
                      (activity): ActivitySelectionListItem => ({
                        ...(activity as Activity),
                        requestForProposalId: request?.id as string,
                        jobName: job.name,
                        jobOriginOrgUnit: job.organizationalUnit
                          ?.name as string,
                        jobId: job.id as string,
                        jobOrganizationalUnitId:
                          job.organizationalUnitId as string,
                      }),
                    );
                  },
                ),
              ),
            };
          },
        ),
        catchError((): Observable<MappedActivitySelectionItems> => {
          this.message.error(this.translate.instant('general.error'));
          return of({ data: [] });
        }),
      );
  }

  private generateActivityView(
    activity: Activity & {
      shift: Shift & { user: User };
      activityCategory: ActivityCategory;
      invoices: ActivityInvoiceModel[];
      jobName?: string;
      jobOriginOrgUnit?: string;
      requestForProposalId?: string;
    },
  ): ActivityViewModel {
    const shift = activity.shift;
    return {
      id: activity.id,
      originalActivity: { ...activity, shift },
      user: `${shift.user.firstName} ${shift.user.lastName}`,
      jobName: activity?.jobName,
      jobOriginOrgUnit: activity?.jobOriginOrgUnit,
      requestForProposalId: activity?.requestForProposalId,
      activityName: this.translate.instant(
        'activity_category.' + activity.activityCategory.name,
      ),
      agreementDetails: {
        clientName: activity.agreement?.client?.name,
        agreementName: activity.agreement?.name,
      },
      shiftDetails: {
        shiftName: shift.name,
        shiftId: shift.id as string,
        shiftReportedStartDate: shift.reportedStartDatetime ?? null,
      },
      formattedStartDateTime: `${
        this.datePipe.transform(activity.startDatetime, 'dd.MM.yy') +
        ' - ' +
        this.datePipe.transform(activity.startDatetime, 'HH:mm')
      }`,
      serviceDetails: {
        serviceName: activity?.service?.name as string,
        serviceId: `${activity?.service?.id}`,
      },
      invoices: activity.invoices,
    };
  }

  private fetchShiftsActivities(
    where: PartialActivityWhereInput,
    invoicing: Invoiced,
    shiftLabelIds: ShiftLabels,
    shiftProjectIds: ShiftProjects,
    shiftUserLabelIds: ShiftUserLabels,
    shiftUserProfessionIds: ShiftUserProfessions,
    state: InvoicingDataStateModel,
    ctx: StateContext<InvoicingDataStateModel>,
  ) {
    return this.activitiesGateway
      .getActivities({
        options: {
          relations: this.invoiceDataHelperService.ACTIVITY_RELATIONS,
          where: where,
          ...invoicing,
          ...shiftLabelIds,
          ...shiftUserLabelIds,
          ...shiftProjectIds,
          ...shiftUserProfessionIds,
          pageNumber: state.pageNumber,
          pageSize: state.pageSize,
          orderBy: {
            startDatetime: 'asc',
          },
        },
      })
      .pipe(
        catchError(() => {
          this.message.error(
            this.translate.instant('general.something_went_wrong'),
          );
          return of({
            data: [],
            pagination: defaults.pagination,
          });
        }),
        tap((activitiesFromServer) => {
          const activities = [...activitiesFromServer.data];
          const processedActivities: ActivityViewModel[] = activities.map(
            (activity) =>
              this.generateActivityView(
                activity as Activity & {
                  shift: Shift & { user: User };
                  activityCategory: ActivityCategory;
                  invoices: ActivityInvoiceModel[];
                },
              ),
          );
          ctx.patchState({
            activities: processedActivities,
            pagination: activitiesFromServer.pagination,
          });
        }),
      );
  }

  private fetchShiftsWithSharedActivities(
    where: PartialActivityWhereInput,
    invoicing: Invoiced,
    shiftLabelIds: ShiftLabels,
    shiftProjectIds: ShiftProjects,
    shiftUserLabelIds: ShiftUserLabels,
    shiftUserProfessionIds: ShiftUserProfessions,
    state: InvoicingDataStateModel,
    ctx: StateContext<InvoicingDataStateModel>,
  ) {
    return combineLatest([
      this.activitiesGateway.getActivities({
        options: {
          relations: this.invoiceDataHelperService.ACTIVITY_RELATIONS,
          where: where,
          ...invoicing,
          ...shiftLabelIds,
          ...shiftUserLabelIds,
          ...shiftProjectIds,
          ...shiftUserProfessionIds,
          pageNumber: state.pageNumber,
          pageSize: state.pageSize,
          orderBy: {
            startDatetime: 'asc',
          },
        },
      }),
      this.fetchAndTransformJobActivities(
        state.filters.dateRange.start,
        state.filters.dateRange.end,
        this.invoiceDataHelperService.JOB_RELATIONS,
      ),
    ]).pipe(
      map(
        ([
          { data: myActivities, pagination: pagination },
          { data: jobActivities },
        ]) => {
          const activities = myActivities.map((activity) => {
            const activityWithJob = jobActivities.find(
              (job) => job.id === activity.id,
            );

            if (activityWithJob) {
              return {
                ...activity,
                jobName: activityWithJob.jobName,
                jobOriginOrgUnit: activityWithJob.jobOriginOrgUnit,
                requestForProposalId: activityWithJob.requestForProposalId,
              };
            } else {
              return activity;
            }
          });

          return {
            activities,
            pagination,
          };
        },
      ),
      tap((activitiesFromServer) => {
        const activities = [...activitiesFromServer.activities] as Activity[];

        const processedActivities: ActivityViewModel[] = activities.map(
          (activity) =>
            this.generateActivityView(
              activity as Activity & {
                shift: Shift & { user: User };
                activityCategory: ActivityCategory;
                invoices: ActivityInvoiceModel[];
              },
            ),
        );

        ctx.patchState({
          activities: processedActivities,
          pagination: activitiesFromServer.pagination,
        });
      }),
    );
  }
}
