import { registerLocaleData } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import de from '@angular/common/locales/de';
import en from '@angular/common/locales/en';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { AbilityModule } from '@casl/angular';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule, Storage } from '@ionic/storage-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule, Store } from '@ngxs/store';
import { TraceService, createErrorHandler } from '@sentry/angular-ivy';
import { AccountModule, UsersState } from '@wilson/account';
import { ActivitiesModule } from '@wilson/activities';
import { AssignmentModule } from '@wilson/assignment';
import { AuthCoreModule, AuthInterceptor, AuthState } from '@wilson/auth/core';
import {
  AuthorizationModule,
  PermissionInterceptor,
} from '@wilson/authorization';
import { ConfigModule, ConfigService } from '@wilson/config';
import { FeatureFlagsModule } from '@wilson/feature-flags';
import {
  DetermineAssignmentsOverlapWebWorker,
  DetermineUnassignedActivitiesOverlapWorker,
} from '@wilson/interfaces';
import { InvoicingStateModule } from '@wilson/invoicing/state';
import { storageAndAuthStateInitializer } from '@wilson/ionic/storage';
import { ShiftTimelineKPISettingsState } from '@wilson/kpi-settings/state';
import { LabelsState } from '@wilson/labels';
import { LangState } from '@wilson/languages';
import { LocationState } from '@wilson/locations';
import { MapboxConfigModule } from '@wilson/mapbox-config';
import { NotificationsModule } from '@wilson/notifications';
import { OperationsBoardSettingState } from '@wilson/operations/state';
import { OperativeReportsState } from '@wilson/operative-reports/state';
import {
  LocationPreferencesState,
  PreferenceSettingsState,
  ShiftTimePreferenceSettingState,
  UserGeneralPreferencesState,
  VehiclePreferencesState,
} from '@wilson/preferences/state';
import { ProfessionState } from '@wilson/profession/core';
import { ProfitabilityScoreState } from '@wilson/profitability-score/state';
import { ProjectsState } from '@wilson/projects/state';
import { QualificationSettingsModule } from '@wilson/qualification-settings';
import { QualificationsModule } from '@wilson/qualifications';
import { RouteModule } from '@wilson/route';
import { NewWilsonJobBucketState } from '@wilson/share/state';
import { ShiftDispositionState } from '@wilson/shift-disposition';
import { ShiftPlanStateModule } from '@wilson/shift-plan/state';
import {
  ShiftAssignmentModalState,
  ShiftTimelineSettingsState,
} from '@wilson/shift-timeline/state';
import { ShiftsModule } from '@wilson/shifts';
import { TalkJsConfigToken } from '@wilson/talkjs/services';
import { TitleManagerModule } from '@wilson/title-manager';
import { provideZxvbnServiceForPSM } from 'angular-password-strength-meter/zxcvbn';
import { de as deDE } from 'date-fns/locale';
import { NZ_DATE_CONFIG, NZ_DATE_LOCALE } from 'ng-zorro-antd/i18n';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzNotificationServiceModule } from 'ng-zorro-antd/notification';
import { ClipboardModule } from 'ngx-clipboard';
import { DateFnsModule } from 'ngx-date-fns';
import { MatomoModule, MatomoRouterModule } from 'ngx-matomo-client';
import { ToastrModule } from 'ngx-toastr';
import { withNgxsResetPlugin } from 'ngxs-reset-plugin';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { HttpLoaderFactory } from './http-loader.factory';
import { ImpressumComponent } from './impressum/impressum.component';
import { JwtOptionsFactory } from './jwt-options.factory';
import { SharedModule } from './shared/shared.module';
import { DetermineAssignmentsOverlapService } from './web-workers/determine-assignments-overlap/determine-assignments-overlap.service';
import { DetermineUnassignedActivitiesOverlapWebWorkerService } from './web-workers/determine-unassigned-activities-overlap/determine-unassigned-activities-overlap.service';
import { SideNavSettingsState } from '@wilson/side-nav-settings/state';
import { OrganizationalUnitsState } from '@wilson/organizational-unit';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { DownloadOutline } from '@ant-design/icons-angular/icons';
import { AgGridColumnSettingsState } from '@wilson/non-domain-specific/ag-grid-custom/ag-grid-column-tree';

registerLocaleData(de);
registerLocaleData(en);

@NgModule({
  declarations: [AppComponent, DatenschutzComponent, ImpressumComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    IonicStorageModule.forRoot({
      name: '__portaldb',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
    }),
    NgxsModule.forRoot(
      [
        AuthState,
        LangState,
        LocationState,
        ShiftTimelineKPISettingsState,
        ShiftDispositionState,
        LocationPreferencesState,
        UserGeneralPreferencesState,
        PreferenceSettingsState,
        ProfessionState,
        VehiclePreferencesState,
        ShiftTimePreferenceSettingState,
        ShiftAssignmentModalState,
        LabelsState,
        OperativeReportsState,
        ProjectsState,
        ProfitabilityScoreState,
        OperationsBoardSettingState,
        SideNavSettingsState,
        ShiftTimelineSettingsState,
        UsersState,
        OrganizationalUnitsState,
        AgGridColumnSettingsState,
      ],
      {
        developmentMode: !environment.production,
        selectorOptions: {
          injectContainerState: false,
        },
      },
    ),
    NgxsStoragePluginModule.forRoot({
      keys: [
        LangState,
        LocationState,
        ShiftTimelineKPISettingsState,
        OperationsBoardSettingState,
        NewWilsonJobBucketState,
        SideNavSettingsState,
        ShiftTimelineSettingsState,
        AgGridColumnSettingsState,
      ],
    }),
    NzMessageModule,
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    NgxsFormPluginModule.forRoot(),
    QualificationsModule,
    QualificationSettingsModule,
    SharedModule,
    ConfigModule.forRoot({
      host: environment.backend.host,
      application: environment.application,
    }),
    MapboxConfigModule.forRoot({
      token: environment.mapboxToken,
    }),
    FeatureFlagsModule.forRoot({
      clientId: environment.launchDarklyClientId,
    }),
    AuthCoreModule,
    AccountModule,
    ShiftsModule,
    ActivitiesModule,
    AssignmentModule,
    AuthorizationModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    DateFnsModule.forRoot(),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: JwtOptionsFactory,
        deps: [Store, ConfigService],
      },
    }),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
    }),
    NotificationsModule,
    FormsModule,
    AbilityModule,
    RouteModule,
    ClipboardModule,
    TitleManagerModule,
    MatomoModule.forRoot({
      disabled: environment.matomo.disabled,
      trackerUrl: environment.matomo.trackerUrl,
      siteId: environment.matomo.siteId,
    }),
    MatomoRouterModule,
    NzNotificationServiceModule,
    InvoicingStateModule,
    ShiftPlanStateModule,
  ],
  providers: [
    {
      provide: DetermineAssignmentsOverlapWebWorker,
      useClass: DetermineAssignmentsOverlapService,
    },
    {
      provide: DetermineUnassignedActivitiesOverlapWorker,
      useClass: DetermineUnassignedActivitiesOverlapWebWorkerService,
    },
    provideZxvbnServiceForPSM(),
    {
      provide: APP_INITIALIZER,
      useFactory: storageAndAuthStateInitializer,
      deps: [Storage, Store],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PermissionInterceptor,
      multi: true,
    },
    {
      provide: TalkJsConfigToken,
      useValue: environment.talkJsConfig,
    },
    {
      provide: NZ_DATE_CONFIG,
      useValue: { firstDayOfWeek: 1 }, // week starts on Monday (Sunday is 0)
    },
    { provide: NZ_DATE_LOCALE, useValue: deDE },
    {
      provide: NZ_ICONS,
      useValue: [DownloadOutline],
    },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler(),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    provideHttpClient(withInterceptorsFromDi()),
    withNgxsResetPlugin(),
  ],
})
export class AppModule {}
