import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import { endOfDay, startOfDay } from 'date-fns';
import {
  FindConditions,
  ShiftInDateRangeDto,
  ShiftWithActivitiesWithLocations,
} from '@wilson/interfaces';
import { ShiftDispositionFilterService } from '@wilson/shift-disposition';
import { ShiftsService } from '@wilson/shifts';
import { cloneDeep } from 'lodash';
import { NzMessageService } from 'ng-zorro-antd/message';
import {
  Subject,
  catchError,
  filter,
  finalize,
  first,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { TimelineDataUtilityStateModel } from '../interfaces';
import { ShiftTimelineDataState } from '../shift-timeline-data/shift-timeline-data.state';
import { defaultTimelineDataUtilityState } from '../shift-timeline-default-data';
import {
  AddUnreleasedShifts,
  FetchProductivityForAllFilteredUsers,
  FetchProductivityOfUsersInDraftTimeline,
  UpdateAssignedShiftsKPIs,
  RemoveUnreleasedShifts,
  UpdateProductivityOfUsers,
  UpdateProductivityOfUsersInDraftTimeline,
} from './timeline-data-utility.actions';
import {
  ProductivitiesResponse,
  Productivity,
  ProductivityKPIGatewayService,
} from '@wilson/api/gateway';

@State({
  name: 'timelineDataStateUtility',
  defaults: defaultTimelineDataUtilityState,
})
@Injectable()
export class TimelineDataUtilityState {
  private unsubFetchUnPublishedShiftsRequestSubject = new Subject();

  constructor(
    private store: Store,
    private shiftsService: ShiftsService,
    private productivityKPIGatewayService: ProductivityKPIGatewayService,
    private readonly shiftDispositionFilterService: ShiftDispositionFilterService,
    private readonly nzMessageService: NzMessageService,
    private readonly translateService: TranslateService,
  ) {}

  @Selector()
  static unreleasedShifts(state: TimelineDataUtilityStateModel) {
    return state.unreleasedShifts;
  }

  @Selector()
  static declinedShifts(state: TimelineDataUtilityStateModel) {
    return state.declinedShifts;
  }

  @Selector()
  static isLoadingUnreleasedShifts(
    state: TimelineDataUtilityStateModel,
  ): boolean {
    return state.isLoadingUnreleasedShifts;
  }

  static filteredUnreleasedShifts(userIds: string[]) {
    return createSelector(
      [TimelineDataUtilityState.unreleasedShifts],
      (unreleasedShifts: ShiftWithActivitiesWithLocations[]) => {
        if (userIds) {
          return unreleasedShifts.filter((unreleasedShift) =>
            userIds.includes(unreleasedShift.userId as string),
          );
        } else {
          return [];
        }
      },
    );
  }

  static filteredDeclinedShifts(userIds: string[]) {
    return createSelector(
      [TimelineDataUtilityState.declinedShifts],
      (declinedShifts: ShiftWithActivitiesWithLocations[]) => {
        if (userIds) {
          return declinedShifts.filter((declinedShifts) =>
            userIds.includes(declinedShifts.userId as string),
          );
        } else {
          return [];
        }
      },
    );
  }

  @Action(AddUnreleasedShifts)
  addUnreleasedShifts(
    ctx: StateContext<TimelineDataUtilityStateModel>,
    action: AddUnreleasedShifts,
  ) {
    const state = ctx.getState();
    const unreleasedShifts = cloneDeep(state.unreleasedShifts);

    unreleasedShifts.push(action.shift as ShiftWithActivitiesWithLocations);

    ctx.patchState({
      unreleasedShifts,
    });
  }

  @Action(RemoveUnreleasedShifts)
  updateUnreleasedShifts(
    ctx: StateContext<TimelineDataUtilityStateModel>,
    action: RemoveUnreleasedShifts,
  ) {
    const state = ctx.getState();
    const unreleasedShifts = cloneDeep(state.unreleasedShifts);
    const unreleasedShiftIndex = unreleasedShifts.findIndex(
      (shift) => shift.id === action.releasedShiftId,
    );

    if (unreleasedShiftIndex) {
      unreleasedShifts.splice(unreleasedShiftIndex, 1);
      ctx.patchState({
        unreleasedShifts,
      });
    }
  }

  @Action(UpdateAssignedShiftsKPIs)
  updateAssignedShiftsKPIs(ctx: StateContext<TimelineDataUtilityStateModel>) {
    this.unsubFetchUnPublishedShiftsRequestSubject.next(null);
    const hasSuccessfullyFetchedUsers = this.store.select(
      ShiftTimelineDataState.hasSuccessfullyFetchedUsers,
    );
    return hasSuccessfullyFetchedUsers.pipe(
      filter((success) => success),
      switchMap(() => {
        const timeframe = this.store.selectSnapshot(
          ShiftTimelineDataState.timeframe,
        );

        ctx.patchState({
          isLoadingUnreleasedShifts: true,
        });

        return this.createFetchAssignedShiftsRequest(
          timeframe.start as Date,
          timeframe.end as Date,
        )
          .pipe(catchError(() => []))
          .pipe(
            first(),
            tap((assignedShifts: ShiftWithActivitiesWithLocations[]) => {
              const unreleasedShifts =
                this.shiftDispositionFilterService.findUnreleasedShifts(
                  assignedShifts,
                  timeframe.start as Date,
                );

              const declinedShifts =
                this.shiftDispositionFilterService.findDeclinedShifts(
                  assignedShifts,
                  timeframe.start as Date,
                );

              ctx.patchState({
                unreleasedShifts,
                declinedShifts,
                isLoadingUnreleasedShifts: false,
              });
            }),
          );
      }),
    );
  }

  private createFetchAssignedShiftsRequest(startDate: Date, endDate: Date) {
    const startDateStartOfDay = startOfDay(startDate);
    const endDateEndOfDay = endOfDay(endDate);
    const userIdsToLookUp = this.store.selectSnapshot(
      ShiftTimelineDataState.timelineUserIds,
    );

    const lookup: FindConditions<ShiftInDateRangeDto> = {
      startDate: startDateStartOfDay.toISOString(),
      endDate: endDateEndOfDay.toISOString(),
      userId: userIdsToLookUp,
    };

    return this.shiftsService
      .getInDateRange(lookup)
      .pipe(takeUntil(this.unsubFetchUnPublishedShiftsRequestSubject));
  }

  @Selector()
  static isLoadingUsersProductivity(state: TimelineDataUtilityStateModel) {
    return state.isLoadingUsersProductivity;
  }
  @Selector()
  static usersProductivities(state: TimelineDataUtilityStateModel) {
    return state.productivitiesResponse.usersProductivities;
  }

  @Selector()
  static hasFetchedUsersProductivitySuccessfully(
    state: TimelineDataUtilityStateModel,
  ) {
    return state.hasFetchedUsersProductivitySuccessfully;
  }

  @Selector()
  static loadingProductivityUserIds(state: TimelineDataUtilityStateModel) {
    return state.loadingProductivityUserIds;
  }

  @Selector()
  static usersOverallProductivity(state: TimelineDataUtilityStateModel) {
    return state.productivitiesResponse.overallProductivity;
  }

  static userProductivityPercentageStream(userId: string) {
    return createSelector(
      [TimelineDataUtilityState.usersProductivities],
      (usersProductivity: Record<string, Productivity>) => {
        return usersProductivity[userId]?.productivityRatio;
      },
    );
  }

  @Action(FetchProductivityForAllFilteredUsers)
  FetchProductivityForAllFilteredUsers(
    ctx: StateContext<TimelineDataUtilityStateModel>,
    action: FetchProductivityForAllFilteredUsers,
  ) {
    const userIds = action.userIds;
    const visibleDateRange = this.store.selectSnapshot(
      ShiftTimelineDataState.visibleDateRange,
    );
    if (visibleDateRange.start && visibleDateRange.end) {
      ctx.patchState({
        isLoadingUsersProductivity: action.showLoading,
      });
      return this.productivityKPIGatewayService
        .getProductivities(userIds, {
          startDatetime: startOfDay(
            new Date(visibleDateRange.start),
          ).toISOString(),
          endDatetime: endOfDay(new Date(visibleDateRange.end)).toISOString(),
        })
        .pipe(
          map((productivitiesResponse: ProductivitiesResponse) => {
            ctx.patchState({
              productivitiesResponse,
              hasFetchedUsersProductivitySuccessfully: true,
            });
          }),
          catchError(() => {
            this.nzMessageService.error(
              this.translateService.instant('general.error'),
            );
            return [];
          }),
          finalize(() => {
            ctx.patchState({
              isLoadingUsersProductivity: false,
            });
          }),
        );
    } else {
      console.warn(
        'Warning: Cannot fetch productivity for invalid time frame',
        visibleDateRange,
      );
      ctx.patchState({
        productivitiesResponse:
          defaultTimelineDataUtilityState.productivitiesResponse,
        isLoadingUsersProductivity: false,
        hasFetchedUsersProductivitySuccessfully: true,
      });
    }
  }

  @Action(UpdateProductivityOfUsers)
  updateProductivityOfUsers(
    ctx: StateContext<TimelineDataUtilityStateModel>,
    action: UpdateProductivityOfUsers,
  ) {
    const userIds = action.userIds;
    const visibleDateRange = this.store.selectSnapshot(
      ShiftTimelineDataState.visibleDateRange,
    );

    if (visibleDateRange.end && visibleDateRange.start) {
      ctx.patchState({
        loadingProductivityUserIds: userIds,
      });

      return this.productivityKPIGatewayService
        .getProductivities(userIds, {
          startDatetime: startOfDay(
            new Date(visibleDateRange.start),
          ).toISOString(),
          endDatetime: endOfDay(new Date(visibleDateRange.end)).toISOString(),
        })
        .pipe(
          map((productivitiesResponse: ProductivitiesResponse) => {
            const clonedProductivitiesResponse = cloneDeep(
              ctx.getState().productivitiesResponse,
            );

            for (const [userId, userProductivity] of Object.entries(
              productivitiesResponse.usersProductivities,
            )) {
              clonedProductivitiesResponse.usersProductivities[userId] =
                userProductivity;
            }

            ctx.patchState({
              productivitiesResponse: clonedProductivitiesResponse,
              loadingProductivityUserIds: [],
            });
          }),
          catchError(() => {
            this.nzMessageService.error(
              this.translateService.instant('general.error'),
            );

            ctx.patchState({
              loadingProductivityUserIds: [],
            });
            return [];
          }),
        );
    } else {
      ctx.patchState({
        productivitiesResponse:
          defaultTimelineDataUtilityState.productivitiesResponse,
        loadingProductivityUserIds: [],
      });
    }
  }

  @Action(FetchProductivityOfUsersInDraftTimeline)
  fetchProductivityOfUsersInDraftTimeline(
    ctx: StateContext<TimelineDataUtilityStateModel>,
    action: FetchProductivityOfUsersInDraftTimeline,
  ) {
    const usersAndShiftIds = action.usersAndShiftIds;

    ctx.patchState({
      isLoadingUsersProductivity: action.showLoading,
      hasFetchedUsersProductivitySuccessfully: false,
    });

    return this.productivityKPIGatewayService
      .getProductivitiesInDraft(usersAndShiftIds)
      .pipe(
        map((productivitiesResponse: ProductivitiesResponse) => {
          ctx.patchState({
            productivitiesResponse,
            isLoadingUsersProductivity: false,
            hasFetchedUsersProductivitySuccessfully: true,
          });
        }),
        catchError(() => {
          this.nzMessageService.error(
            this.translateService.instant('general.error'),
          );

          ctx.patchState({
            productivitiesResponse:
              defaultTimelineDataUtilityState.productivitiesResponse,
            isLoadingUsersProductivity: false,
            hasFetchedUsersProductivitySuccessfully: false,
          });
          return [];
        }),
      );
  }

  @Action(UpdateProductivityOfUsersInDraftTimeline)
  updateProductivityOfUsersInDraftTimeline(
    ctx: StateContext<TimelineDataUtilityStateModel>,
    action: UpdateProductivityOfUsersInDraftTimeline,
  ) {
    const usersAndShiftIds = action.usersAndShiftIds;
    const userIds = usersAndShiftIds.map(
      (userAndShiftIds) => userAndShiftIds.userId,
    );

    if (userIds) {
      ctx.patchState({
        loadingProductivityUserIds: userIds,
      });

      return this.productivityKPIGatewayService
        .getProductivitiesInDraft(usersAndShiftIds)
        .pipe(
          map((productivitiesResponse: ProductivitiesResponse) => {
            ctx.patchState({
              productivitiesResponse,
              loadingProductivityUserIds: [],
            });
          }),
          catchError(() => {
            this.nzMessageService.error(
              this.translateService.instant('general.error'),
            );

            ctx.patchState({
              loadingProductivityUserIds: [],
            });
            return [];
          }),
        );
    } else {
      ctx.patchState({
        productivitiesResponse:
          defaultTimelineDataUtilityState.productivitiesResponse,
        loadingProductivityUserIds: [],
      });
    }
  }
}
